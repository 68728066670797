<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Visits ({{totalQuoteCount}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex left-0 overflow-auto mb-2 items-center">
          <!-- <div class="pr-2  text-gray4 heading-5">Entity:</div> -->
          <div class="" v-for="(status, index) in entStatusList" :key="index" >
            <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveA(index, status)" :selected="status.selected" :btnText="status.displayText" :lastInlist="entStatusList.length === index + 1"/>     
          </div>
        </div>
        <div class="flex  overflow-auto mb-2 items-center">
          <!-- <div class="pr-2 text-gray4 heading-5">Employee:</div> -->
          <!-- <div class="" v-for="(status, index) in empStatusList" :key="index" :class="index === 0 ? 'left-0 sticky bg-white' : ''">
            <StatusBtn  class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
          </div> -->
           <div class="" v-for="(status, index) in empStatusList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
            <StatusBtn v-if="activeUer !== status.userDetailId" class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
            <StatusBtn v-else class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="'Me'" :lastInlist="empStatusList.length === index + 1"/>     
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="visitLIst.length > 0">
          <div v-for="(data, index) in visitLIst" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showVisitDetail(data)">
            <div class="">
              <!-- <p class="  text-text1 font-bold">{{}}</p> -->
              <div class="flex justify-between items-center">
                <p class="text-gray4 heading-5 overFlowParaA">{{data.displayText}}</p>
                <div class="text-gray4 heading-5 flex items-center" v-if="!data.isCompleted">
                  {{data.dateStatus}}
                  <span v-if="data.dateStatus === 'Late'" class="text-error h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <span v-if="data.dateStatus === 'Today'" class="text-secondary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></span>
                    <p v-if="data.dateStatus === 'Upcoming'" class="text-primary h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></p>
                </div>
                <div class="text-gray4 heading-5 flex items-center" v-else>
                  Completed
                  <p class="text-success h-5 w-5 pl-1"><i class="fa-solid fa-square-full"></i></p>
                </div>
              </div>
              <div class="flex justify-between items-center">
                <p class="text-text1 heading-4 py-1 font-semibold whitespace-pre-line">{{data.jobTitle}}</p>
                <p class="text-primary heading-5" v-if="data.runningTimer > 0"><i class="fas fa-circle-play"></i></p>
              </div>
              <p class="text-gray4 heading-5">{{data.companyName}}</p>
              <div class="flex flex-nowrap cursor-pointer">
                <div class="" v-for="(user, index) in data.visitAssignee" :key="index">
                  <Chip v-if="activeUer !== user.userDetailId" :chipText="`${user.firstName}  ${user.lastName}`"/>
                  <Chip v-else :chipText="`Me`"/>
                </div> 
              </div>
              </div>
          </div>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>
        <div class=" mt-4">
          <Pagination
            :totalDataCount="totalQuoteCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination"/>
        </div>
      </div>
     </div>
   </div>
</template>
<script>
import {getCookies} from '@/utils/cookies'
import Cookies from 'vue-cookies'
var moment = require('moment')
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
// import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import {getDisplayText} from '@/utils/convertDateAndTime.js'
import Chip from '../components/chip.vue'
export default {
  name: "activity",
  components: {
    NoRecords,
    StatusBtn,
    Pagination,
    Chip,
    // Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      activeUer: 0,
      entStatusList: [
        {
          vistStatusId: 1,
          displayText: 'Action Required',
          id: 'actionRequired',
          selected: false,
        },
        {
          vistStatusId: 2,
          displayText: 'Upcoming',
          id: 'upcoming',
          selected: false,
        },
        {
          vistStatusId: 3,
          displayText: 'Today',
          id: 'today',
          selected: false,
        },
        {
          vistStatusId: 4,
          displayText: 'Late',
          id: 'late',
          selected: false,
        },
        {
          vistStatusId: 5,
          displayText: 'Running',
          id: 'running',
          selected: false,
        },
        {
          vistStatusId: 6,
          displayText: 'Archived',
          id: 'archived',
          selected: false,
        },
        {
          vistStatusId: 7,
          displayText: 'Not Assigned',
          id: 'notAssignee',
          selected: false,
        },
      ],
      empStatusList: [],
      visitLIst: [],
      searchForQuotation: '',
      getQuotationDebounce: null,
      resetPaginationValues: false,
      addLead: false,
      totalQuoteCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        isActive: true,
        sortBy: 'addedDate',
        entStatusList: [],
        empStatusList: [],
        customerId: '',
        from: '',
        to: '',
      },
    };
  },
  created() {},
  mounted() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
     this.getQuotationDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      if (response) {
        this.getVisitList(false, false)
      }
      this.addLead = false
    });
    this.getEmployeeList()
    this.getVisitList(false, false)
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    toggleActiveAndInactiveA (id, dataVal) {
        this.entStatusList.map((data, i) => {
            if (dataVal.id === data.id) {
              if (this.entStatusList[i].selected) {
                 this.filterObject.entStatusList = []
                 this.entStatusList[i].selected = false
              } else {
                  this.filterObject.entStatusList = data.id
                 this.entStatusList[i].selected = true
              }
            } else {
              this.entStatusList[i].selected = false
            }
        })
        this.getVisitList(false, true)
    },
    toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.filterObject.empStatusList = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.empStatusList.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.empStatusList.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.filterObject.empStatusList = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.getVisitList(false, true)
      var date = new Date();
      date.setTime(date.getTime()+(2*60*60*1000));
      var expires = "; expires="+date.toGMTString();
      Cookies.set('visitFilter', this.filterObject.empStatusList, expires)
      console.log('filter', filter)
    },
    toggleActiveAndInactiveC () {
      this.filterObject.empStatusList = []
      this.empStatusList.forEach((a) => {
        if (a.selected) {
          this.filterObject.empStatusList.push(a.userDetailId)
        }
      })
      this.getVisitList(false, true)
    },
    showVisitDetail (data) {
      this.$router.push({name: 'visitDetail', params: {visitId: data.visitId}})
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      this.getVisitList(true, false)
    },
    getEmployeeList () {
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          const findex = temp.findIndex(x => x.userDetailId === this.activeUer)
          if (findex > -1) {
            let element = temp[findex];
            temp.splice(findex, 1);
            temp = [element, ...temp];
          }
          for (let index = 0; index < temp.length; index++) {
            temp[index].selected = false
            temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
          }
          const newFirstElement = {
            userDetailId: 0,
            fullName: "All",
            selected: false
          }
          this.empStatusList = [newFirstElement].concat(temp)
          let tempA = getCookies('visitFilter')
          console.log('visitFilter', tempA)
          if (tempA !== null) {
            const array = tempA.split(',');
            for (let II = 0; II < this.empStatusList.length; II++) {
              for (let JJ = 0; JJ < array.length; JJ++) {
                console.log('fasasdadd', JSON.parse(array[JJ]))
                if (this.empStatusList[II].userDetailId === JSON.parse(array[JJ])) {
                  this.empStatusList[II].selected = true
                }
              }
            }
            this.toggleActiveAndInactiveC()
          } else{
            for (let index = 0; index < temp.length; index++) {
              temp[index].selected = true
              temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
            }
            const newFirstElement = {
              userDetailId: 0,
              fullName: "All",
              selected: true
            }
            this.empStatusList = [newFirstElement].concat(temp)
          }
          console.log('this.empStatusList', this.empStatusList)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getVisitList (showlistLoader, reset) {
      if (showlistLoader && this.searchForQuotation !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (reset) {
        this.resetPaginationValues = true
      }
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getvisitLIst(
        0,
        this.filterObject.entStatusList,
        this.filterObject.start,
        this.filterObject.length,
        this.filterObject.empStatusList,
        response => {
          this.totalQuoteCount = response.count
          let temp = response.Data !== null ? response.Data : []
          for (let index = 0; index < temp.length; index++) {
            if (temp[index].visitEndDateTime !== '' && temp[index].visitStartDateTime !== '') {
              temp[index].displayText = getDisplayText(temp[index].visitStartDateTime, temp[index].visitEndDateTime)
            } else {
             temp[index].displayText = '--'
            }
            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            currentDate = new Date(currentDate);
            const cFormated = moment(currentDate).format('YYYY-MM-DD');
            if ( moment(new Date(temp[index].visitStartDateTime)).format('YYYY-MM-DD',) === cFormated) {
              temp[index].dateStatus = 'Today';
            } else if ( new Date(temp[index].visitStartDateTime).getTime() < currentDate.getTime()) {
              temp[index].dateStatus = 'Late';
            } else {
              temp[index].dateStatus = 'Upcoming';
            }
          }
          this.visitLIst = temp
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>